import React from "react";
import PropTypes from "prop-types";

import { Table, Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./About.scss";

const About = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, th0, th1, td0, td1, price0, price1, price2, price3 } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Table className="text-center" bordered size="sm">
      <thead>
        <tr>
          <th> </th>
          <th>{th0}</th>
          <th>{th1}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{td0}</td>
          <td>{price0}</td>
          <td>{price2}</td>
        </tr>
        <tr>
          <td>{td1}</td>
          <td>{price1}</td>
          <td>{price3}</td>
        </tr>
      </tbody>
      </Table>
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

About.defaultProps = {
  className: null,
  frontmatter: null,
};

export default About;
