import React from "react";
import PropTypes from "prop-types";

import { Button, Row, Form } from "react-bootstrap";
import styled from "styled-components";

import { Formik } from "formik";
import * as Yup from "yup";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./Contact.scss";

// Styled-component
const MYFORM = styled(Form)`
  width: 90%;
  text-align: left;
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  @media (min-width: 786px) {
    width: 50%;
  }
`;
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          phone: "",
          message: "",
        }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "reservierungsanfrage", ...values }),
          })
            .then(() => {
              alert("Thanks for your request. We'll be in touch soon!");
              actions.resetForm();
            })
            .catch(() => {
              alert("Error");
            })
            .finally(() => actions.setSubmitting(false));
        }}
        validationSchema={Yup.object({
          fullName: Yup.string().max(30, "Must be 30 characters or less.").required("Required"),
          email: Yup.string().email("Invalid email address.").required("Required"),
          phone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid.")
            .required("Required"),
          message: Yup.string().trim().required("Required"),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <MYFORM
            name="reservierungsanfrage"
            onSubmit={handleSubmit}
            className="mx-auto"
            data-netlify={true}
          >
            <Form.Group controlId="formName">
              <Form.Label>Name :</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullName}
                className={touched.fullName && errors.fullName ? "error" : null}
              />
              {touched.fullName && errors.fullName ? (
                <div className="error-message">{errors.fullName}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>eMail :</Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={touched.email && errors.email ? "error" : null}
              />
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone number :</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={touched.phone && errors.phone ? "error" : null}
              />
              {touched.phone && errors.phone ? (
                <div className="error-message">{errors.phone}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message :</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                className={touched.message && errors.message ? "error" : null}
              />
              {touched.message && errors.message ? (
                <div className="error-message">{errors.message}</div>
              ) : null}
            </Form.Group>

            <Button size="l" variant="primary" className="text-uppercase" type="submit">
              Send request
            </Button>
          </MYFORM>
        )}
      </Formik>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
