import React from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";

const ServiceItem = ({ iconName, content, externalHref0,
  externalHrefText0,
  externalHref1,
  externalHrefText1,
  externalHref2,
  externalHrefText2,
  externalHref3,
  externalHrefText3 }) => {
  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  return (
    <>
      {iconPart}
      <p className="text-muted">{content}</p>
      {externalHref0 != null && 
      <a href={externalHref0}>{externalHrefText0}<br /></a> }
      {externalHref1 != null &&
      <a href={externalHref1}>{externalHrefText1}<br /></a> }
      {externalHref2 != null &&
      <a href={externalHref2}>{externalHrefText2}<br /></a> }
      {externalHref3 != null && 
      <a href={externalHref3}>{externalHrefText3}<br /></a> }
      <br />
    </>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  content: PropTypes.string,
  externalHref0: PropTypes.string,
  externalHrefText0: PropTypes.string,
  externalHref1: PropTypes.string,
  externalHrefText1: PropTypes.string,
  externalHref2: PropTypes.string,
  externalHrefText2: PropTypes.string,
  externalHref3: PropTypes.string,
  externalHrefText3: PropTypes.string
};

ServiceItem.defaultProps = {
  iconName: null,
  content: "",
  externalHref0: null,
  externalHrefText0: "",
  externalHref1: null,
  externalHrefText1: "",
  externalHref2: null,
  externalHrefText2: "",
  externalHref3: null,
  externalHrefText3: ""
};

export default ServiceItem;
