import React from "react";
import PropTypes from "prop-types";

import "./TeamMember.scss";

const TeamMember = ({
  header,
  subheader,
  externalHref0,
  externalHrefText0,
  externalHref1,
  externalHrefText1,
  externalHref2,
  externalHrefText2,
  externalHref3,
  externalHrefText3
}) => {

  return (
    <div className="team-member">
      <h4>{header}</h4>
      <p className="text-muted">{subheader}</p>
      {externalHref0 != null &&
      <a href={externalHref0}>{externalHrefText0}<br /></a> }
      {externalHref1 != null &&
      <a href={externalHref1}>{externalHrefText1}<br /></a> }
      {externalHref2 != null &&
      <a href={externalHref2}>{externalHrefText2}<br /></a> }
      {externalHref3 != null && 
      <a href={externalHref3}>{externalHrefText3}<br /></a> }
    </div>
  );
};

TeamMember.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  externalHref0: PropTypes.string,
  externalHrefText0: PropTypes.string,
  externalHref1: PropTypes.string,
  externalHrefText1: PropTypes.string,
  externalHref2: PropTypes.string,
  externalHrefText2: PropTypes.string,
  externalHref3: PropTypes.string,
  externalHrefText3: PropTypes.string
};

TeamMember.defaultProps = {
  header: "",
  subheader: "",
  externalHref0: null,
  externalHrefText0: "",
  externalHref1: null,
  externalHrefText1: "",
  externalHref2: null,
  externalHrefText2: "",
  externalHref3: null,
  externalHrefText3: ""
};

export default TeamMember;
