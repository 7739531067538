import makeFAIcon from "utils/makeFAIcon";

import {
  faEnvelope,
  faPlus,
  faBars,
  faTimes,
  faGlobe,
  faAsterisk,
  faPaw,
  faParking,
  faBroadcastTower,
  faHiking,
  faUtensils,
  faSpa,
  faSwimmer,
  faSwimmingPool,
  faGolfBall
} from "@fortawesome/free-solid-svg-icons";

export const EnvelopIcon = makeFAIcon(faEnvelope);
export const PlusIcon = makeFAIcon(faPlus);
export const BarsIcon = makeFAIcon(faBars);
export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const AsterikIcon = makeFAIcon(faAsterisk);
export const PawIcon = makeFAIcon(faPaw);
export const ParkingIcon = makeFAIcon(faParking);
export const BroadcastTowerIcon = makeFAIcon(faBroadcastTower);
export const HikingIcon = makeFAIcon(faHiking);
export const UtensilsIcon = makeFAIcon(faUtensils);
export const SpaIcon = makeFAIcon(faSpa);
export const SwimmerIcon = makeFAIcon(faSwimmer);
export const SwimmingPoolIcon = makeFAIcon(faSwimmingPool);
export const GolfIcon = makeFAIcon(faGolfBall);
