import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import Icon from"components/Icon";
import PageSection from "components/PageSection";
import "./Portfolio.scss";

const Portfolio = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, portfolios, subheader0, subheader1, subcontent0, subcontent1, subcontent2, subcontent3, subcontent4 } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row className="text-center mb-5">
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
          <Col lg={4} className="ml-auto text-center">
            <Icon iconName="ParkingIcon" size="2x" className="text-muted mb-3"/>
            <p className="text-muted">{subcontent2}</p>
          </Col>
          <Col lg={4} className="ml-auto text-center">
            <Icon iconName="PawIcon" size="2x" className="text-muted mb-3"/>
            <p className="text-muted">{subcontent3}</p>
          </Col>
          <Col lg={4} className="ml-auto text-center">
            <Icon iconName="BroadcastTowerIcon" size="2x" className="text-muted mb-3"/>
            <p className="text-muted">{subcontent4}</p>
          </Col>
      </Row>
      <Row className="text-center">
          <Col md="auto" className="text-center">
            <br /> <br />
            <h4 className="text-muted">{subheader0}</h4>
            <p className="text-muted">{subcontent0}</p>
          </Col>
      </Row>
      <Row>
        {portfolios.map(
          ({ content, header, imageFileName, imageFileNameDetail, subheader }) => (
            <PortfolioItem
              key={header}
              imageFileName={imageFileName}
              header={header}
              subheader={subheader}
              content={content}
              imageFileNameDetail={imageFileNameDetail}
            />
          ),
        )}
      </Row>
      <Row className="text-center">
          <Col md="auto" className="text-center">
            <br /> <br />
            <h4 className="text-muted">{subheader1}</h4>
            <p className="text-muted">{subcontent1}</p>
          </Col>
      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Portfolio;
