import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Home = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, services, quote, subquote} = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        {services.map((service) => (
          <Col md="auto" key={service.header}>
            <ServiceItem {...service} />
          </Col>
        ))}
      </Row>
      <Col md="auto" className="text-center">
        <br />
        <h4 className="text-muted">{quote}</h4>
        <p className="text-muted">{subquote}</p>
        </Col>
    </PageSection>
  );
};

Home.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Home.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Home;
